export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyC5i1KsjXohOcfCah0rUPXgOZi9NyWTM2s",
        authDomain: "awo-care.firebaseapp.com",
        projectId: "awo-care",
        storageBucket: "awo-care.appspot.com",
        messagingSenderId: "916102094521",
        appId: "1:916102094521:web:b5bc5be388c4f7fa8ac568",
        measurementId: "G-ML9GL99F71"
    },
    useEmulators: false,
    googleMapsAPIKey: "AIzaSyAwR2CCpirGqq1gyvTFSkovadg8VKHFY8c"
};
